import React from 'react';
import FlashcardsSection from './components/FlashcardsSection';

export default function LearningSection() {
  const modules = [
    {
      title: "Module 1: Basic Conversations",
      description: "Learn everyday English conversations and greetings",
      progress: 80,
    },
    {
      title: "Module 2: Shopping & Numbers",
      description: "Master shopping-related vocabulary and numbers",
      progress: 60,
    },
    {
      title: "Module 3: Travel & Directions",
      description: "Essential phrases for navigation and travel",
      progress: 40,
    },
    {
      title: "Module 4: Food & Dining",
      description: "Restaurant vocabulary and ordering food",
      progress: 20,
    },
  ];

  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Learning Journey</h1>
          <p className="text-xl text-gray-600">
            Track your progress and explore our interactive learning resources
          </p>
        </div>

        {/* Learning Modules Section */}
        <div className="mb-16">
          <h2 className="text-2xl font-semibold text-purple-600 mb-8">Your Learning Modules</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {modules.map((module, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg p-6">
                <h3 className="text-xl font-semibold text-gray-900 mb-2">{module.title}</h3>
                <p className="text-gray-600 mb-4">{module.description}</p>
                <div className="relative pt-1">
                  <div className="flex mb-2 items-center justify-between">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-purple-600 bg-purple-200">
                        Progress
                      </span>
                    </div>
                    <div className="text-right">
                      <span className="text-xs font-semibold inline-block text-purple-600">
                        {module.progress}%
                      </span>
                    </div>
                  </div>
                  <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                    <div
                      style={{ width: `${module.progress}%` }}
                      className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"
                    ></div>
                  </div>
                  <button className="text-purple-600 hover:text-purple-700 font-medium">
                    Continue Learning →
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Practice Tools Section */}
        <div className="mb-16">
          <h2 className="text-2xl font-semibold text-purple-600 mb-8">Practice Tools</h2>
          
          {/* Voice Translator Card */}
          <div className="mb-8">
            <div className="bg-purple-50 rounded-lg p-8">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Voice Translator</h3>
              <p className="text-gray-700 mb-6">
                Practice your English pronunciation with our real-time voice translator. 
                Speak in Hindi and get instant English translations.
              </p>
              <a
                href="/translator"
                className="inline-block bg-purple-600 text-white px-6 py-3 rounded-md font-medium hover:bg-purple-700 transition-colors duration-200"
              >
                Try Voice Translator
              </a>
            </div>
          </div>

          {/* Flashcards */}
          <div>
            <h3 className="text-xl font-semibold text-gray-900 mb-4">Practice Flashcards</h3>
            <FlashcardsSection />
          </div>
        </div>

        {/* Additional Resources Section */}
        <div>
          <h2 className="text-2xl font-semibold text-purple-600 mb-8">Additional Resources</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Video Lessons</h3>
              <p className="text-gray-600 mb-4">
                Access our library of video lessons covering various English topics.
              </p>
              <a href="#" className="text-purple-600 hover:text-purple-700 font-medium">
                Coming Soon →
              </a>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Practice Exercises</h3>
              <p className="text-gray-600 mb-4">
                Test your knowledge with interactive exercises and quizzes.
              </p>
              <a href="#" className="text-purple-600 hover:text-purple-700 font-medium">
                Coming Soon →
              </a>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Study Materials</h3>
              <p className="text-gray-600 mb-4">
                Download worksheets and study guides for offline learning.
              </p>
              <a href="#" className="text-purple-600 hover:text-purple-700 font-medium">
                Coming Soon →
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}