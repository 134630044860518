import React, { useState, useEffect, useRef } from 'react';
import { Mic, StopCircle, Volume2 } from 'lucide-react';
import axios from 'axios';

export default function VoiceTranslator() {
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [translation, setTranslation] = useState('');
  const [error, setError] = useState('');
  const recognitionRef = useRef(null);

  useEffect(() => {
    // Check if browser supports speech recognition
    if (!('webkitSpeechRecognition' in window) && !('SpeechRecognition' in window)) {
      setError('Speech recognition is not supported in this browser.');
      return;
    }

    // Initialize speech recognition
    const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
    recognitionRef.current = new SpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;
    recognitionRef.current.lang = 'hi-IN'; // Set to Hindi

    recognitionRef.current.onresult = async (event) => {
      const current = event.resultIndex;
      const transcript = event.results[current][0].transcript;
      setTranscript(transcript);
      
      // Translate when we get a final result
      if (event.results[current].isFinal) {
        try {
          // Using a free translation API
          const response = await axios.get('https://translate.googleapis.com/translate_a/single', {
            params: {
              client: 'gtx',
              sl: 'hi',
              tl: 'en',
              dt: 't',
              q: transcript
            }
          });
          
          const translatedText = response.data[0][0][0];
          setTranslation(translatedText);
          speakTranslation(translatedText);
        } catch (err) {
          setError('Translation error: ' + err.message);
        }
      }
    };

    recognitionRef.current.onerror = (event) => {
      setError('Error occurred in recognition: ' + event.error);
    };

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
    };
  }, []);

  const speakTranslation = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = 'en-US';
    window.speechSynthesis.speak(utterance);
  };

  const toggleListening = () => {
    if (isListening) {
      recognitionRef.current.stop();
    } else {
      recognitionRef.current.start();
    }
    setIsListening(!isListening);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50/80 py-16">
      <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-lg">
        <div className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Voice Translator</h2>
          <p className="text-gray-600">Speak in Hindi, and I'll translate to English in real-time</p>
        </div>

        {error && (
          <div className="bg-red-50 text-red-500 p-4 rounded-md mb-4">
            {error}
          </div>
        )}

        <div className="space-y-6">
          <div className="flex items-center justify-center">
            <button
              onClick={toggleListening}
              className={`p-4 rounded-full ${
                isListening 
                  ? 'bg-red-100 text-red-600 hover:bg-red-200' 
                  : 'bg-purple-100 text-purple-600 hover:bg-purple-200'
              } transition-colors`}
            >
              {isListening ? <StopCircle size={24} /> : <Mic size={24} />}
            </button>
          </div>

          {transcript && (
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="text-sm text-gray-500 mb-2">Hindi (Original):</div>
              <div className="text-lg text-gray-900">{transcript}</div>
            </div>
          )}

          {translation && (
            <div className="p-4 bg-purple-50 rounded-lg">
              <div className="text-sm text-purple-600 mb-2">English (Translation):</div>
              <div className="text-lg text-gray-900">{translation}</div>
              <button
                onClick={() => speakTranslation(translation)}
                className="mt-2 flex items-center text-purple-600 hover:text-purple-700"
              >
                <Volume2 size={16} className="mr-1" />
                <span className="text-sm">Listen</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
} 