import React from 'react';
import zoieImage from './images/zoie_shaktilearn.jpeg';
import gunjanImage from './images/gunjan_shaktilearn.jpeg';
import samyaImage from './images/samya_shaktilearn.jpeg';
import raginiImage from './images/ragini_shaktilearn.jpeg';
import varshaImage from './images/varsha_shaktilearn.jpeg';
import priyankaImage from './images/priyanka_shaktilearn.jpeg';
import sheetalImage from './images/sheetal_shaktilearn.jpeg';
import smritiImage from './images/smriti_shaktilearn.jpeg';
import akshitaImage from './images/akshita_shaktilearn.jpeg';
import amritaImage from './images/amrita_shaktilearn.jpeg';
import kamleshImage from './images/kamlesh_shaktilearn.jpeg';
import shuchiImage from './images/shuchi_shaktilearn.jpeg';
import artiImage from './images/arti_shaktilearn.jpeg';
import prachiImage from './images/prachi_shaktilearn.jpeg';
import anjumImage from './images/anjum_shaktilearn.jpeg';
import mansiImage from './images/mansi_shaktilearn.jpeg';
import taraImage from './images/tara_shaktilearn.jpeg';
import uzmaImage from './images/uzma_shaktilearn.jpeg';
import garimaImage from './images/garima_shaktilearn.jpeg';
import nidhiImage from './images/nidhi_shaktilearn.jpeg';
import harshitaImage from './images/harshita_shaktilearn.jpeg';
import kirtiImage from './images/kirti_shaktilearn.jpeg';


export default function TeamSection() {
  return (
    <div className="min-h-screen bg-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-12">Meet Our Team</h1>

        {/* Founder Section */}
        <div className="mb-16">
          <h2 className="text-2xl font-semibold text-purple-600 mb-8">Founder</h2>
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <img src={zoieImage} alt="Zoie Kapur" className="rounded-lg shadow-lg object-cover w-full h-[400px]" />
            </div>
            <div>
              <h3 className="text-2xl font-bold mb-4">Zoie Kapur</h3>
              <p className="text-gray-600 mb-4">Founder & CEO</p>
              <p className="text-gray-700 leading-relaxed mb-6">
                A high school student at Cranleigh Abu Dhabi, Zoie founded Shakti Learn in 2024. She recognised the need for a tool to bridge the opportunity gap for young women in India after realising the transformative power speaking English holds. She is committed to the cause and aims to one day fully bridge the opportunity gap through accessible education.
              </p>
              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <div className="w-2 h-2 bg-purple-600 rounded-full"></div>
                  <p className="text-gray-700">Founded Shakti Learn in 2024</p>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="w-2 h-2 bg-purple-600 rounded-full"></div>
                  <p className="text-gray-700">High School Student at Cranleigh Abu Dhabi</p>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="w-2 h-2 bg-purple-600 rounded-full"></div>
                  <p className="text-gray-700">Passionate about educational equity</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Core Team Section */}
        <div className="mb-16">
          <h2 className="text-2xl font-semibold text-purple-600 mb-8">Core Team</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                name: 'Gunjan Yadav',
                role: 'Program Lead',
                image: gunjanImage,
                bio: 'An English major at Shiv Nadar University who is incredibly passionate about writing. She uses her English skills to help the underprivileged and has been associated with Shakti Learn since its founding.'
              },
              {
                name: 'Samya Gautam',
                role: 'Professional Development Lead',
                image: samyaImage,
                bio: 'An Economics major at Delhi University who uses her technical skills to help women improve their public speaking as part of their professional development with Shakti Learn. She has been with Shakti Learn since its creation in 2024.'
              },
              {
                name: 'Ragini Raghav',
                role: 'Senior Educational Advisor',
                image: raginiImage,
                bio: 'Ragini Raghav is a retired teacher with 26 years of experience, now dedicated to empowering underprivileged girls through education, helping them build brighter futures.'
              }
            ].map((member) => (
              <TeamMember key={member.name} {...member} />
            ))}
          </div>
        </div>

        {/* Mentors Section */}
        <div className="mb-16">
          <h2 className="text-2xl font-semibold text-purple-600 mb-8">Mentors</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                name: 'Varsha Sharma',
                role: 'Mathematics Mentor',
                image: varshaImage,
                bio: 'A distinguished medalist from IIT, Varsha teaches applied mathematics to engineering students. She dedicates her spare time to teaching underprivileged girls basic mathematics, helping them prepare for senior school enrollment.'
              },
              {
                name: 'Priyanka Sharma',
                role: 'Career Development Mentor',
                image: priyankaImage,
                bio: 'An accomplished HR manager specializing in recruitment strategies for multinational companies. Priyanka empowers young women by preparing senior students for entry-level jobs, focusing on roles that uphold labor rights and offering essential skills training.'
              },
              {
                name: 'Sheetal Sirohi',
                role: 'Science Education Mentor',
                image: sheetalImage,
                bio: 'An MSc Botany gold medalist with a BEd and diploma in counseling, Sheetal teaches Biology at Nirmala Convent School, Bulandshahr. She is dedicated to fostering curiosity and academic excellence in her students.'
              },
              {
                name: 'Smriti Gupta',
                role: 'Lead Educator',
                image: smritiImage,
                bio: 'Smriti Gupta is a passionate educator with 15 years of teaching experience, committed to transforming lives through education. Alongside her work in schools, she champions the cause of free education for underprivileged children and girls, striving to create opportunities and inspire a brighter tomorrow for those in need.'
              },
              {
                name: "Akshita Singh",
                role: "Career Development Mentor",
                bio: "Akshita Singh is an HR professional with over 12 years of experience in talent acquisition and talent management across various industries in the MENA region. At Shakti Learn, she empowers girls by helping them understand the importance of having a job and equipping them with the skills needed to enter the job market.",
                image: akshitaImage
              },
              {
                name: 'Amrita Devi',
                role: 'Lead Educator',
                image: amritaImage,
                bio: 'Amrita Devi, an educator with 18 years of experience, including 8 as a principal, is dedicated to teaching underprivileged girls. She strives to empower them through education, inspiring them to overcome challenges and achieve their dreams.'
              },
              {
                name: 'Dr. Kamlesh Verma',
                role: 'Lead Educator',
                image: kamleshImage,
                bio: 'Dr. Kamlesh Verma is an educator with a Ph.D. and 25 years of teaching experience. Dr. Verma is dedicated to teaching underprivileged children, making education accessible to those in need.'
              }
            ].map((member) => (
              <TeamMember key={member.name} {...member} />
            ))}
          </div>
        </div>

        {/* Teachers Section */}
        <div className="mb-16">
          <h2 className="text-2xl font-semibold text-purple-600 mb-8">Teachers</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                name: 'Shuchi Agarwal',
                role: 'Social Sciences Teacher',
                image: shuchiImage,
                bio: 'Shuchi Agarwal is a passionate social sciences teacher in primary school, dedicated to making learning interactive and meaningful for her students. She emphasizes critical thinking and understanding social issues to inspire young minds. In her spare time, she mentors underprivileged children, helping them access education and opportunities.'
              },
              {
                name: 'Arti Rana',
                role: 'English Teacher',
                image: artiImage,
                bio: 'Arti Rana is a dedicated English teacher who also mentors underprivileged girls, empowering them through education and language skills.'
              },
              {
                name: 'Prachi Sharma',
                role: 'Computer Science Teacher',
                image: prachiImage,
                bio: 'Prachi Sharma is a dedicated computer science teacher in primary school who also teaches underprivileged girls, equipping them with essential digital skills for a brighter future.'
              },
              {
                name: 'Anjum Rani',
                role: 'English Teacher',
                image: anjumImage,
                bio: 'Anjum Rani is a committed English teacher who devotes her time to empowering underprivileged girls, helping them excel in language and achieve their potential.'
              },
              {
                name: 'Mansi Trachi',
                role: 'Computer Science Teacher',
                image: mansiImage,
                bio: 'Mansi Trachi is a dedicated computer science teacher in primary school, passionate about introducing young minds to technology. She also helps underprivileged girls learn how to use computers, opening doors to new opportunities and skills for their future.'
              },
              {
                name: 'Tara Yadav',
                role: 'Kindergarten Teacher',
                image: taraImage,
                bio: 'Tara Yadav is a compassionate kindergarten teacher. In her spare time, she helps underprivileged children grasp basic English and math skills.'
              },
              {
                name: 'Uzma Yunus',
                role: 'Educator',
                image: uzmaImage,
                bio: 'Uzma Yunus is a dedicated educator with a passion for inspiring students to reach their full potential. She is committed to creating an inclusive learning environment that fosters curiosity and growth. In addition to her teaching, Uzma actively supports underprivileged children, offering them access to quality education and opportunities for success.'
              },
              {
                name: 'Garima Yadav',
                role: 'Teacher',
                image: garimaImage,
                bio: 'Garima Yadav is a passionate teacher focused on helping students thrive academically and personally. She works to create a positive and engaging classroom environment, encouraging her students to explore new ideas. Garima also extends her support to underprivileged children, providing them with valuable educational opportunities.'
              },
              {
                name: 'Nidhi Yadav',
                role: 'Prep Teacher',
                image: nidhiImage,
                bio: 'Nidhi Yadav is a dedicated prep teacher who fosters a love for learning in her young students. She focuses on building a strong educational foundation in a nurturing and supportive environment. Nidhi also extends her efforts to teach underprivileged children, giving them a head start in their educational journey.'
              },
              {
                name: 'Harshita Trashi',
                role: 'Science Teacher',
                image: harshitaImage,
                bio: 'Harshita Trashi is an enthusiastic science teacher for primary and middle school students. She is passionate about making science engaging and accessible, fostering curiosity and critical thinking in young minds. In her spare time, she teaches girls who cannot afford to attend school, empowering them with knowledge and opportunities for a brighter future.'
              },
              {
                name: 'Kirti Sharma',
                role: 'Primary School Teacher',
                image: kirtiImage,
                bio: 'Kirti Sharma is a dedicated primary school teacher specializing in English. Beyond the classroom, she passionately supports underprivileged girls, providing them with guidance and opportunities to grow. Her commitment to education and empowerment inspires her students and community alike.'
              }
            ].map((member) => (
              <TeamMember key={member.name} {...member} />
            ))}
          </div>
        </div>

        {/* Call to Action */}
        <div className="bg-purple-50 rounded-lg p-8 text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Join Our Mission</h2>
          <p className="text-gray-600 mb-6">
            We're always looking for passionate individuals to join our team and help make a difference.
          </p>
          <a
            href="/join"
            className="inline-block bg-purple-600 text-white px-8 py-3 rounded-md font-medium hover:bg-purple-700 transition-colors duration-200"
          >
            View Open Positions
          </a>
        </div>
      </div>
    </div>
  );
}

function TeamMember({ name, role, image, bio }) {
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      <img src={image} alt={name} className="w-full h-64 object-cover" />
      <div className="p-6">
        <h3 className="text-xl font-semibold text-gray-900 mb-2">{name}</h3>
        <p className="text-purple-600 mb-4">{role}</p>
        <p className="text-gray-600">{bio}</p>
      </div>
    </div>
  );
}