import React from 'react';
import { TrendingUp, Globe, Heart, BookOpen } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function StorySection() {
  const navigate = useNavigate();

  const handleStartLearning = () => {
    navigate('/learning');
  };

  const handleJoinUs = () => {
    navigate('/join');
  };

  return (
    <div className="min-h-screen bg-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-12">Our Story</h1>
        
        {/* Mission Statement */}
        <div className="mb-16 text-center max-w-3xl mx-auto">
          <p className="text-xl text-gray-700 leading-relaxed italic">
            "In India, English proficiency can mean the difference between a life of struggle and a life of opportunity. We're here to bridge that gap."
          </p>
          <p className="text-purple-600 mt-4">- Zoie Kapur, Founder</p>
        </div>

        {/* The Problem */}
        <div className="mb-16">
          <h2 className="text-2xl font-semibold text-purple-600 mb-8">The Challenge</h2>
          <div className="bg-gray-50 rounded-lg p-8">
            <div className="grid md:grid-cols-2 gap-8">
              <div>
                <h3 className="text-xl font-semibold mb-4">The Language Barrier</h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <span className="bg-purple-100 p-2 rounded-full mr-4">
                      <TrendingUp className="h-5 w-5 text-purple-600" />
                    </span>
                    <span>Only 10% of Indians speak English fluently, despite it being crucial for professional growth</span>
                  </li>
                  <li className="flex items-start">
                    <span className="bg-purple-100 p-2 rounded-full mr-4">
                      <Globe className="h-5 w-5 text-purple-600" />
                    </span>
                    <span>90% of multinational companies in India require English proficiency</span>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-4">The Access Gap</h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <span className="bg-purple-100 p-2 rounded-full mr-4">
                      <Heart className="h-5 w-5 text-purple-600" />
                    </span>
                    <span>Quality English education remains inaccessible to millions of young women</span>
                  </li>
                  <li className="flex items-start">
                    <span className="bg-purple-100 p-2 rounded-full mr-4">
                      <BookOpen className="h-5 w-5 text-purple-600" />
                    </span>
                    <span>Traditional language programs are often unaffordable or impractical for working women</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Our Solution */}
        <div className="mb-16">
          <h2 className="text-2xl font-semibold text-purple-600 mb-8">Our Solution</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                title: "Personalized Learning",
                description: "One-on-one mentoring tailored to each woman's goals, schedule, and learning pace."
              },
              {
                title: "Holistic Development",
                description: "Beyond language skills, we focus on professional development and digital literacy."
              },
              {
                title: "Community Support",
                description: "A network of mentors, peers, and alumni providing continuous encouragement."
              }
            ].map((solution) => (
              <div key={solution.title} className="bg-white rounded-lg shadow-lg p-6">
                <h3 className="text-xl font-semibold text-gray-900 mb-4">{solution.title}</h3>
                <p className="text-gray-600">{solution.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Impact Statistics */}
        <div className="mb-16 bg-purple-50 rounded-lg p-8">
          <h2 className="text-2xl font-semibold text-center text-gray-900 mb-8">Why English Matters</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                stat: "25-50%",
                label: "Higher Salary for English Speakers"
              },
              {
                stat: "2x",
                label: "More Likely to Secure Professional Roles"
              },
              {
                stat: "87%",
                label: "Report Increased Confidence"
              }
            ].map((stat) => (
              <div key={stat.label} className="text-center">
                <div className="text-4xl font-bold text-purple-600">{stat.stat}</div>
                <div className="text-gray-600 mt-2">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Call to Action */}
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Join Our Mission</h2>
          <p className="text-gray-600 mb-8 max-w-2xl mx-auto">
            Whether you're a student looking to learn, a professional wanting to mentor, or an organization interested in partnering, there's a place for you in our story.
          </p>
          <div className="flex justify-center gap-4 mt-8">
            <button
              onClick={handleStartLearning}
              className="bg-purple-600 text-white px-8 py-3 rounded-md font-medium hover:bg-purple-700 transition-colors"
            >
              Start Learning
            </button>
            <button
              onClick={handleJoinUs}
              className="text-purple-600 px-8 py-3 rounded-md font-medium border border-purple-600 hover:bg-purple-50 transition-colors"
            >
              Join Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}