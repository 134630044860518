import { BookOpen, Building, Users } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function HomeSection() {
  const navigate = useNavigate();

  const handleStartLearning = () => {
    navigate('/learning');
  };

  const handleBecomeMentor = () => {
    navigate('/join'); // Assuming we have a join/signup page for mentors
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-pink-50/80">
      {/* Hero Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 text-center">
        <h1 className="text-6xl font-extrabold tracking-tight">
          <span className="text-gray-900 block">Empowering Young Indian Women</span>
          <span className="text-purple-600 block">Through Education</span>
        </h1>
        
        <p className="mt-6 text-gray-600 text-xl max-w-3xl mx-auto">
          Free personalized mentoring in English communication, digital skills, and professional development. Building pathways to economic independence.
        </p>

        <div className="mt-10 flex justify-center gap-4">
          <button 
            onClick={handleStartLearning}
            className="bg-purple-600 text-white px-8 py-3 rounded-md font-medium hover:bg-purple-700 transition-colors"
          >
            Start Learning
          </button>
          <button 
            onClick={handleBecomeMentor}
            className="text-purple-600 px-8 py-3 rounded-md font-medium border border-purple-600 hover:bg-purple-50 transition-colors"
          >
            Become a Mentor
          </button>
        </div>
      </div>

      {/* Stats Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid grid-cols-4 gap-8">
          {[
            { number: "500+", label: "Students Enrolled" },
            { number: "200+", label: "Active Mentors" },
            { number: "15+", label: "NGO Partners" },
            { number: "85%", label: "Employment Rate" }
          ].map((stat) => (
            <div key={stat.label} className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 p-6 text-center">
              <div className="text-5xl font-bold text-purple-600">{stat.number}</div>
              <div className="text-gray-600 mt-2">{stat.label}</div>
            </div>
          ))}
        </div>
      </div>

      {/* Programs Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 text-center">
        <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Programs</h2>
        <p className="text-xl text-gray-600 mb-16">Comprehensive training designed for success</p>
        
        <div className="grid md:grid-cols-3 gap-8">
          {[
            {
              title: "English Language",
              description: "One-on-one virtual tutoring focused on business communication and industry-specific vocabulary.",
              icon: "📚"
            },
            {
              title: "Digital Skills",
              description: "Computer literacy, software training, and basic coding skills for the modern workplace.",
              icon: "💻"
            },
            {
              title: "Professional Development",
              description: "Career guidance, interview preparation, and workplace culture training.",
              icon: "🚀"
            }
          ].map((program) => (
            <article 
              key={program.title} 
              className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 p-8 text-left"
            >
              <div className="text-4xl mb-4">{program.icon}</div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">{program.title}</h3>
              <p className="text-gray-600">{program.description}</p>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}