import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './components/Layout';
import HomeSection from './HomeSection';
import LearningSection from './LearningSection';
import JoinSection from './JoinSection';
import TeamSection from './TeamSection';
import StorySection from './StorySection';
import InitiativesSection from './InitiativesSection';
import ImpactSection from './ImpactSection';
import VoiceTranslator from './components/VoiceTranslator';
import FlashcardsSection from './components/FlashcardsSection';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <HomeSection />,
      },
      {
        path: "/learning",
        element: <LearningSection />,
      },
      {
        path: "/story",
        element: <StorySection />,
      },
      {
        path: "/team",
        element: <TeamSection />,
      },
      {
        path: "/impact",
        element: <ImpactSection />,
      },
      {
        path: "/initiatives",
        element: <InitiativesSection />,
      },
      {
        path: "/join",
        element: <JoinSection />,
      },
      {
        path: "/translator",
        element: <VoiceTranslator />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;