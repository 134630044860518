import React, { useState } from 'react';
import { ChevronRight, ChevronLeft, Volume2 } from 'lucide-react';

const categories = {
  "Greetings and polite phrases": [
    { english: "Hello", hindi: "नमस्ते" },
    { english: "Good morning", hindi: "शुभ प्रभात" },
    { english: "Good afternoon", hindi: "शुभ दोपहर" },
    { english: "Good evening", hindi: "शुभ संध्या" },
    { english: "Good night", hindi: "शुभ रात्रि" },
    { english: "How are you doing?", hindi: "आप कैसे हैं?" },
    { english: "I'm doing well, thank you", hindi: "मैं अच्छा हूँ, धन्यवाद" },
    { english: "Pleased to meet you", hindi: "आपसे मिलकर खुशी हुई" },
    { english: "What is your name?", hindi: "आपका क्या नाम है?" },
    { english: "My name is", hindi: "मेरा नाम है" }
  ],
  "Small talk and casual conversations": [
    { english: "How's everything?", hindi: "सब कैसे चल रहा है?" },
    { english: "What have you been up to?", hindi: "आजकल आप क्या कर रहे हैं?" },
    { english: "How was your weekend?", hindi: "आपका सप्ताहांत कैसा था?" },
    { english: "Have a good day", hindi: "आपका दिन शुभ हो" },
    { english: "Until next time", hindi: "अगली बार तक" },
    { english: "Stay safe", hindi: "सुरक्षित रहें" },
    { english: "Where do you live", hindi: "आप कहाँ रहते हैं" },
    { english: "I live in India", hindi: "मैं भारत में रहता हूँ" },
    { english: "How's the weather?", hindi: "मोसम केसा हे?" },
    { english: "The weather is good", hindi: "मौसम अच्छा है" },
    { english: "The weather is bad", hindi: "मौसम खराब है" }
  ],
  "Asking for help or information": [
    { english: "Could you help me?", hindi: "क्या आप मेरी मदद कर सकते हैं?" },
    { english: "Where can I find this place?", hindi: "मुझे यह जगह कहां मिल सकती है?" },
    { english: "What time is it?", hindi: "समय क्या हुआ?" },
    { english: "What is the price of this?", hindi: "यह कितना पैसा है?" },
    { english: "I couldn't understand that", hindi: "मैं उसे समझ नहीं पाया" },
    { english: "Could you please say it again?", hindi: "कृपया क्या आप यह दोबारा बोल सकते हैं?" },
    { english: "Please could you speak slowly?", hindi: "क्या आप कृपया धीरे से बोल सकते हैं?" },
    { english: "Thank you", hindi: "धन्यवाद" }
  ],
  "Politeness and courtesy": [
    { english: "Please let me know", hindi: "कृपया मुझे बताओ" },
    { english: "Thanks a lot", hindi: "बहुत-बहुत धन्यवाद" },
    { english: "No worries", hindi: "कोई चिंता नहीं" },
    { english: "Pardon me", hindi: "मुझे क्षमा करें" },
    { english: "I'm sorry", hindi: "मुझे माफ़ करें" },
    { english: "That's fine with me", hindi: "कि मेरे साथ ठीक है" },
    { english: "Absolutely", hindi: "बिल्कुल" },
    { english: "Nice to see you again", hindi: "आप को दुबारा देखकर अच्छा लगा" },
    { english: "Sleep well", hindi: "अच्छे से सो" }
  ],
  "At a restaurant or shop": [
    { english: "May I see the menu?", hindi: "क्‍या मुझे मेनू मिल सकता है?" },
    { english: "I'll have this, please", hindi: "कृपया, मैं इसे ले लूँगा" },
    { english: "Can I please have a bill?", hindi: "क्या मुझे कृपया बिल मिल सकता है?" },
    { english: "Do you sell this?", hindi: "क्या आप इसे बेचते हैं?" },
    { english: "Can I pay in cash?", hindi: "क्या मैं नकद भुगतान कर सकता हूँ?" },
    { english: "Can I pay in card?", hindi: "क्या मैं कार्ड से भुगतान कर सकता हूँ?" },
    { english: "Where is the nearest bathroom?", hindi: "निकटतम बाथरूम कहाँ है?" }
  ]
};

export default function FlashcardsSection() {
  const [selectedCategory, setSelectedCategory] = useState(Object.keys(categories)[0]);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);

  const currentCards = categories[selectedCategory];

  const nextCard = () => {
    setIsFlipped(false);
    setCurrentCardIndex((prev) => (prev + 1) % currentCards.length);
  };

  const previousCard = () => {
    setIsFlipped(false);
    setCurrentCardIndex((prev) => (prev - 1 + currentCards.length) % currentCards.length);
  };

  const speakText = (text, language) => {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = language === 'hindi' ? 'hi-IN' : 'en-US';
    utterance.rate = 0.8; // Slightly slower speed for better clarity
    window.speechSynthesis.speak(utterance);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50/80 py-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-12">Flashcards</h1>

        {/* Category Selection */}
        <div className="mb-8">
          <label className="block text-sm font-medium text-gray-700 mb-2">Select Category:</label>
          <select
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value);
              setCurrentCardIndex(0);
              setIsFlipped(false);
            }}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
          >
            {Object.keys(categories).map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>

        {/* Flashcard */}
        <div 
          className="relative h-64 w-full perspective-1000 mb-8 cursor-pointer"
          onClick={() => setIsFlipped(!isFlipped)}
        >
          <div 
            className={`relative w-full h-full transition-transform duration-500 transform-style-preserve-3d ${isFlipped ? 'rotate-y-180' : ''}`}
          >
            {/* Front (English) */}
            <div className="absolute w-full h-full bg-white rounded-xl shadow-lg p-8 backface-hidden">
              <div className="flex flex-col items-center justify-center h-full">
                <p className="text-2xl font-semibold text-gray-900 mb-4">
                  {currentCards[currentCardIndex].english}
                </p>
                <div className="flex items-center space-x-4">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      speakText(currentCards[currentCardIndex].english, 'english');
                    }}
                    className="flex items-center space-x-2 text-purple-600 hover:text-purple-700"
                  >
                    <Volume2 size={20} />
                    <span className="text-sm">Listen</span>
                  </button>
                  <p className="text-sm text-gray-500">Click card to flip</p>
                </div>
              </div>
            </div>
            {/* Back (Hindi) */}
            <div className="absolute w-full h-full bg-purple-50 rounded-xl shadow-lg p-8 backface-hidden rotate-y-180">
              <div className="flex flex-col items-center justify-center h-full">
                <p className="text-3xl font-semibold text-gray-900 mb-4">
                  {currentCards[currentCardIndex].hindi}
                </p>
                <div className="flex items-center space-x-4">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      speakText(currentCards[currentCardIndex].hindi, 'hindi');
                    }}
                    className="flex items-center space-x-2 text-purple-600 hover:text-purple-700"
                  >
                    <Volume2 size={20} />
                    <span className="text-sm">सुनें</span>
                  </button>
                  <p className="text-sm text-gray-500">Click card to flip back</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Card Controls */}
        <div className="flex justify-center space-x-4">
          <button
            onClick={previousCard}
            className="flex items-center px-4 py-2 border border-purple-600 rounded-md text-purple-600 hover:bg-purple-50"
          >
            <ChevronLeft size={20} className="mr-1" />
            Previous
          </button>
          <button
            onClick={() => setIsFlipped(!isFlipped)}
            className="px-4 py-2 bg-purple-600 rounded-md text-white hover:bg-purple-700"
          >
            Flip Card
          </button>
          <button
            onClick={nextCard}
            className="flex items-center px-4 py-2 bg-purple-600 rounded-md text-white hover:bg-purple-700"
          >
            Next
            <ChevronRight size={20} className="ml-1" />
          </button>
        </div>

        {/* Progress */}
        <div className="mt-4 text-center text-sm text-gray-500">
          Card {currentCardIndex + 1} of {currentCards.length}
        </div>
      </div>
    </div>
  );
} 