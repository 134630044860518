import React from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();

  return (
    <nav className="bg-white py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <Link to="/" className="text-2xl font-bold text-purple-600">
            Shakti Learn
          </Link>

          <div className="flex items-center space-x-12">
            <Link to="/" className="text-gray-600 hover:text-gray-900">
              Home
            </Link>
            <Link to="/team" className="text-gray-600 hover:text-gray-900">
              Our Team
            </Link>
            <Link to="/impact" className="text-gray-600 hover:text-gray-900">
              Impact
            </Link>
            <Link to="/story" className="text-gray-600 hover:text-gray-900">
              Our Story
            </Link>
            <Link to="/learning" className="text-gray-600 hover:text-gray-900">
              Learning
            </Link>
            <Link 
              to="/join" 
              className="bg-purple-600 text-white px-6 py-2 rounded-md hover:bg-purple-700 transition-colors"
            >
              Join Us
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

const Layout = () => {
  return (
    <div>
      <Navbar />
      <Outlet />
    </div>
  );
};

export default Layout; 