import React from 'react';
import { TrendingUp, Users, Globe } from 'lucide-react';

export default function ImpactSection() {
  return (
    <div className="min-h-screen bg-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-12">Our Impact</h1>

        {/* Key Metrics */}
        <div className="grid md:grid-cols-4 gap-8 mb-16">
          {[
            {number: "85%", label: "Found Better Employment"},
            {number: "3x", label: "Average Income Increase"},
            {number: "500+", label: "Women Empowered"},
            {number: "12", label: "States Reached"}
          ].map((metric) => (
            <div key={metric.label} className="text-center">
              <div className="text-4xl font-bold text-purple-600">{metric.number}</div>
              <div className="text-gray-600 mt-2">{metric.label}</div>
            </div>
          ))}
        </div>

        {/* Success Stories */}
        <div className="mb-16">
          <h2 className="text-2xl font-semibold text-purple-600 mb-8">Success Stories</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {[
              {
                name: "Meera Kumari",
                location: "Bihar",
                story: "From not being able to speak English at all, Meera now works as a customer service representative at a multinational company, earning 3x her previous income.",
                impact: "Income increased from ₹8,000 to ₹24,000 monthly",
                image: "https://picsum.photos/seed/meera/800/500"
              },
              {
                name: "Lakshmi Devi",
                location: "Tamil Nadu",
                story: "After completing our digital skills program, Lakshmi started her own online business selling handicrafts internationally through e-commerce platforms.",
                impact: "Established successful e-commerce business with global reach",
                image: "https://picsum.photos/seed/lakshmi/800/500"
              }
            ].map((story) => (
              <div key={story.name} className="bg-white rounded-lg shadow-lg overflow-hidden">
                <img src={story.image} alt={story.name} className="w-full h-48 object-cover" />
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">{story.name}</h3>
                  <p className="text-purple-600 mb-4">{story.location}</p>
                  <p className="text-gray-700 mb-4">{story.story}</p>
                  <div className="bg-purple-50 p-4 rounded-lg">
                    <p className="text-purple-700 font-semibold">{story.impact}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Impact Areas */}
        <div className="mb-16">
          <h2 className="text-2xl font-semibold text-purple-600 mb-8">How We Create Change</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: <TrendingUp className="h-8 w-8 text-purple-600" />,
                title: "Economic Empowerment",
                description: "Our graduates see significant income increases through better job opportunities and career advancement."
              },
              {
                icon: <Users className="h-8 w-8 text-purple-600" />,
                title: "Community Impact",
                description: "Educated women become role models in their communities, inspiring others to pursue education and careers."
              },
              {
                icon: <Globe className="h-8 w-8 text-purple-600" />,
                title: "Global Opportunities",
                description: "English proficiency opens doors to international education and remote work opportunities."
              }
            ].map((area) => (
              <div key={area.title} className="bg-white rounded-lg shadow-lg p-6">
                <div className="w-12 h-12 rounded-md bg-purple-100 flex items-center justify-center mb-4">
                  {area.icon}
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-4">{area.title}</h3>
                <p className="text-gray-600">{area.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Call to Action */}
        <div className="bg-purple-50 rounded-lg p-8 text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Be Part of Our Story</h2>
          <p className="text-gray-600 mb-6">
            Join us in empowering more women through education and professional development.
          </p>
          <button className="bg-purple-600 text-white px-8 py-3 rounded-md font-medium hover:bg-purple-700 transition-colors duration-200">
            Support Our Mission
          </button>
        </div>
      </div>
    </div>
  );
} 